@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";

//BTOCSITE-9942
@mixin unmanned-store-middle-target {
    @media screen and (max-width: 1460px) and (min-width: 768px){
        @content;
    }
}

.cont-wrap {
    max-width:100%;
    padding: 0;
    background-color: #fff;
    @include mobile {
        border:none;
    }
}

.pc-only {
    display: inline-block;
    @include mobile {
        display:none;
    }
}

.mo-only {
    display: none;
    @include mobile {
        display: inline-block;
    }
} 

.unmanned-store-head {
    position: relative;
    .inner {
        &.head {
            position: relative;
            max-width: 1380px;
            display: flex;
            margin: 23px auto 64px auto;
            text-align: center;
            // justify-content: center;
            align-items: center;
            .headTitle {
                margin: 0 auto;
                font-size: 24px;
            }
            .shareBox {
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                top: -5px;
                .shareTitle {
                    font-size: 14px;
                }
                .component-wrap {
                    position: relative;
                    padding:0; //BTOCSITE-12528 (공유하기 수정 틀어짐) 
                }
            }
        }
    }
    @include mobile {
        .inner {
            &.head {
                margin-bottom: 33px;
                .headTitle {
                    width: 216px;
                    line-height: 34px;
                }
                .shareBox {
                    right: 16px;
                    top: 0px;
                    .shareTitle {
                        display: none;
                    }
                    .component-wrap {
                        position: relative;
                    }
                    .title {
                        font-size: 16px;
                        line-height: 24px;
                        width: auto;
                    }
                }
            }
        }
    }
}
//BTOCSITE-9942
.visual-wrap {
    position:relative;
    img {
        width:100%;
    }
    .bg {
        display:block;
        width:100%;
        height:100%;
        background-position:center center;
        background-size:auto 100%;
        background-repeat:no-repeat;
    }
    .tit-wrap {
        position:absolute;
        top:50%;
        left:50%;
        width: 100%;
        max-width: 1380px;
        transform:translate(-50%,-50%);
        color: $color-white;
        @include mobile {
            top:48px;
            right:20px;
            left: 20px;
            width: calc(100% - 20px);
            padding-right:20px;
            transform:translateY(0);
        }
        .tit {
            font-size:48px;
            line-height:64px;
            font-weight:700;
            @include mobile {
                font-size:26px;
                line-height:35px;
                font-weight: 700;
            }
        }
        .txt {
            display:block;
            padding-top:20px;
            font-size:24px;
            line-height:34px;
            font-weight:400;

            sub{
                @include font-small;
                margin-top: 12px;
            }

            @include mobile {
                padding-top:10px;
                font-size:16px;
                line-height:24px;
                font-weight: 400;
            }
            .small-txt {
                margin-top: 15px;
                font-size: 13px;
                line-height: 1.5;
                @include mobile {
                    font-size: 13px;
                    line-height: 1.5;
                }
                & + .small-txt {
                    margin-top:5px
                }
            }
        }
        .btn-area {
            margin-top: 32px;
            .btn {
                padding: 7px 15px;
                @include font-small;
                @include pc {
                    border-color: $color-white !important;
                    color: $color-white;
                }
                @include mobile {
                    padding:10px 19px;
                }
                &:hover,
                &:focus,
                &:active {
                    border-color: $color-primary !important;
                }
            }
        }
    }
    @include unmanned-store-middle-target {
        overflow: hidden;
        height: 400px;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            height: 100%;
            transform: translate(-50%, -50%);
            object-fit: cover;
        }
        .tit-wrap {
            width: calc(100% - 20px);
            padding-left: 20px;
            .tit {
                font-size: 36px;
                line-height: 1.5;
                font-weight: 700;
            }
            .txt {
                font-size: 18px;
                line-height: 1.5;
            }
            .small-txt {
                font-size: 12px;
            }
        }
    }
}

.page-header {
    &.fixed {
        margin-bottom: 0;
        padding-top: 0;
        background-color: $color-bg-wt;
    }
}
@include mobile {
    .page-header {
        padding:0;
        &.border {
            border-bottom:12px solid #f4f4f4;
        }
        .title {
            display:none;
        }
    }
    .tabs-wrap.btn-type {
        padding-top:40px;
        text-align:center;
    }
}

.unmanned-store-content {
    width: 100%;
    margin-top: 90px;
    @include mobile {
        margin-top: 55px;
    }
    .unmanned-store-intro-wrap {
        max-width: 1380px;
        margin: 0 auto;
            @include mobile {
                max-width: 100%;
                padding: 0 16px;
            }
    }
    .unmanned-store-introdution {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;
        @include mobile {
            display: block;
            margin-bottom: 60px;
            text-align: center;
        }
        .unmanned-store-text {
            width: 560px;
            padding-top: 56px;
            padding-right: 40px;

            .num {
                display: none;
                margin-bottom: 12px;
                font-weight: 700;
                @include font-large-2;
                color: $color-gray-1;
            }
            .tit {
                font-weight: 700;
                @include font(40px,56px);
                word-break: keep-all;
            }
            .desc {
                margin-top: 16px;
                @include font($font-medium,26px);
                word-break: keep-all;
                .txt {
                    display: block;
                    margin-top: 16px;
                    @include font-small;
                    color: $color-gray-1;
                }
            }
            .bullet-list {
                margin-top: 12px;
            }
            .btn-area {
                margin-top: 32px;
            }
            @include mobile {
                width: 100%;
                padding: 0 16px 32px;
                .tit {
                    @include font(27px,38px);
                }
                .desc {
                    margin-top: 12px;
                    @include font(15px,23px);
                }
                .btn-area {
                    margin-top: 24px;
                }
                .bullet-list {
                    margin-top: 8px;
                    .b-txt {
                        display: inline-block;
                    }
                }
            }
        }
        .unmanned-store-visual {
            @include pc{
                max-width: 678px;
            }
            
            //flex: 1;
            img {
                max-width: 100%;
                height: auto;
                vertical-align: top;
            }
        }
        &.center {
            align-items: center;
            .unmanned-store-text {
                padding-top: 0;
                @include mobile {
                    width: 100%;
                    padding: 0 20px 32px;
                }
            }
        }
        &.right {
            .unmanned-store-text {
                order: 2;
                padding-right: 0;
                padding-left: 40px;
                @include mobile {
                    width: 100%;
                    padding: 0 20px 32px;
                }
            }
            .unmanned-store-visual {
                order: 1;
            }
        }
    }
}
.unmanned-store-map{
    .pc-only{
        display: inline-block;
        @include mobile{
            display: none;
        }
    }
    
   .inner {
        max-width: 1380px + 80px;
        margin: 0 auto;
        padding: 0 40px;

        //BTOCSITE-3289 무인매장 이용가이드 이북 제작 및 업로드 요청 - start
        .content-tab-head {
            position: relative;

            @include mobile {
                text-align: center;
            }

            .btn-experience-guide {
                display: inline-block;
                position: absolute;
                right:0;
                top:50%;
                font-size:0;
                transform:translateY(-50%);
                z-index:1;

                

                &:before {
                    content:"";
                    display: inline-block;
                    width:24px;
                    height:24px;
                    margin-right:4px;
                    vertical-align: middle;
                    background: url('/lg5-common/images/MA/unmanned-store/img_manual_MO_128.svg') no-repeat 0 0;
                    background-size: 100% 100%;
                }
                &:after {
                    content:"";
                    display: inline-block;
                    width:16px;
                    height:16px;
                    margin-left:4px;
                    vertical-align: middle;
                    background: url('/lg5-common/images/icons/btn-arr-16-black.svg') no-repeat 0 0;
                    background-size: 100% 100%;
                }

                
                span {
                    display: inline-block;
                    font-size:16px;
                    font-weight:bold;
                    color:#000;
                    padding-bottom:2px;
                    line-height:1.2;
                    vertical-align:middle;
                    @include text-underline(0, #000);
                }

                @include mobile {
                    position: static;
                    transform:none;
                    margin-bottom:45px;

                    span {
                        font-size:14px;
                    }
                }  
                
            }

             
        }
        //BTOCSITE-3289 무인매장 이용가이드 이북 제작 및 업로드 요청 - end


        .service-title {
            margin-bottom: 64px;
            @include font(40px,56px);
            text-align: center;
        }
        @include mobile {
            max-width: 100%;
            padding: 0px;
            .service-title {
                margin-bottom: 16px; //BTOCSITE-3289 무인매장 이용가이드 이북 제작 및 업로드 요청
                @include font(27px,38px);
            }
        }
        .map-area{
            padding-top: 80px;
            padding-bottom: 80px;
            @include mobile{
                padding-top: 40px;
                padding-bottom: 60px;
            }
            .box{
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                @include mobile { 
                    flex-wrap: wrap; 
                }
            }
            .map{
                width: 973px;
                min-width: 973px;
                height: 536px;
                position: relative;

                @include unmanned-store-middle-target {
                     width: 74.84615vw;
                    min-width: 74.84615vw;
                    height: 41.23077vw
                }

                @include mobile{
                    width: 100vw;
                    min-width: 100%;
                    height: 92.96089vw;
                }
                &.seoul{
                    background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_seoul.svg') no-repeat 0 0; 
                    background-size: 100%;
                    @include mobile{ background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_seoul-m.svg') no-repeat 0 0; background-size: 100%; }
                    .pin{
                        &.idx01{ left: 56.1%; top: 59.1%; } //강남
                        &.idx02{ left: 71.8%; top: 51.5%; } //강동
                        &.idx03{ left: 54.7%; top: 22%; } //강북
                        &.idx04{ left: 26.3%; top: 47.2%; } //강서
                        &.idx05{ left: 33.8%; top: 79.7%; } //금천
                        &.idx06{ left: 39.5%; top: 67.4%; } //봉천
                        &.idx07{ left: 37.8%; top: 24.2%; } //불광
                        &.idx08{ left: 50.8%; top: 74.4%; } //서초
                        &.idx09{ left: 55.7%; top: 2.2%; } //쌍문
                        &.idx10{ left: 60.3%; top: 32.5%; } //노원 // BTOCSITE-40781 야간무인매장 6월 지점 변경
                        @include mobile{
                            &.idx01{ left: 55.5%; top: 57.1%; } //강남
                            &.idx02{ left: 78.8%; top: 51.5%; } //강동
                            &.idx03{ left: 53.7%; top: 25%; } //강북
                            &.idx04{ left: 10.8%; top: 48.1%; } //강서
                            &.idx05{ left: 22.8%; top: 76.5%; } //금천
                            &.idx06{ left: 31.5%; top: 64.7%; } //봉천
                            &.idx07{ left: 28.2%; top: 27.2%; } //불광
                            &.idx08{ left: 50.8%; top: 74.4%; } //서초
                            &.idx09{ left: 54.9%; top: 5.2%; } //쌍문
                            &.idx10{ left: 62%; top: 36.5%; } //노원 // BTOCSITE-40781 야간무인매장 6월 지점 변경
                        }          
                    }
                    // & ~ .list {
                    //     > ol {
                    //         -moz-column-count: 2;
                    //         -webkit-column-count: 2;
                    //         column-count: 2;
                    //         @include unmanned-store-middle-target {
                    //             -moz-column-count: auto;
                    //             -webkit-column-count: auto;
                    //             column-count: auto;
                    //         }
                    //         li:nth-child(10n){
                    //             break-before: column;
                    //             margin-top: 0;
                    //             @include unmanned-store-middle-target {
                    //                 break-before: auto;
                    //                 margin-top: 2vw;
                    //             }
                    //         }
                    //     }
                    // }
                }
                &.gyeonggi{

                    background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_gyeonggi.png') no-repeat 0 0; 
                    background-size: 100%;
                    
                    @include mobile{ background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_gyeonggi-m.png') no-repeat 0 0; background-size: 100%; }

                    .pin{
                        &.idx01{ left: 21.5%; top: 54.8%; } //부평구청
                        &.idx02{ left: 18.5%; top: 71.1%; } //송도
                        &.idx03{ left: 42.2%; top: 88.2%; } //수원
                        &.idx04{ left: 23.2%; top: 25.4%; } //운정
                        &.idx05{ left: 28.5%; top: 34.7%; } //일산
                        &.idx06{ left: 18.6%; top: 46.3%; } //청라
                        &.idx07{ left: 37.2%; top: 70.2%; } //평촌
                        &.idx08{ left: 24.4%; top: 65%; } //인천 // BTOCSITE-40781 야간무인매장 6월 지점 변경
                        @include mobile{
                            &.idx01{ left: 13.5%; top: 56.2%; } //부평구청
                            &.idx02{ left: 9.5%; top: 70.1%; } //송도
                            &.idx03{ left: 48.2%; top: 86.4%; } //수원
                            &.idx04{ left: 18.2%; top: 26.4%; } //운정
                            &.idx05{ left: 25.8%; top: 37%; } //일산
                            &.idx06{ left: 7.6%; top: 46.2%; } //청라
                            &.idx07{ left: 39.2%; top: 69%; } //평촌
                            &.idx08{ left: 16.5%; top: 66.5%; } //인천 // BTOCSITE-40781 야간무인매장 6월 지점 변경
                        }          
                    }
                }
                &.chungcheong{
                    background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_chungcheong.svg') no-repeat 0 0;
                    background-size: 100%;
                    @include mobile{
                        background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_chungcheong-m.svg') no-repeat 0 0;
                        background-size: 100%;
                    }
                    .pin{
                        &.idx01{ left: 44.4%; top: 44.3%; } //남청주
                        &.idx02{ left: 33.4%; top: 32%; } //아산
                        @include mobile{
                            &.idx01 {left: 44.4%; top: 41.3%; }
                            &.idx02 {left: 27.6%; top: 34.2%; }
                        }
                    }
                }
                &.jeolla{
                    background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_jeolla.svg') no-repeat 0 0;
                    background-size: 100%;
                    @include mobile{
                        background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_jeolla-m.svg') no-repeat 0 0;
                        background-size: 100%;
                    }
                    .pin{
                        &.idx01{ left: 45.3%; top: 14.7%; } //서광주
                        &.idx02{ left: 36.9%; top: 48.7%; } //전주

                        @include mobile{
                            &.idx01 {left: 54.4%; top: 8.4%; }
                            &.idx02 {left: 34.9%; top: 44.7%; }
                        }
                    }
                }
                &.gyeongsang{ 
                    background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_gyeongsang.svg') no-repeat 0 0;
                    background-size: 100%;
                    @include mobile{
                        background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_gyeongsang-m.svg') no-repeat 0 0;
                        background-size: 100%;
                    }
                    .pin{
                        &.idx01{
                            left: 59.9%;
                            top: 80.7%;
                            padding:14px 0 0 20px;
                            background-image: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin_reverse.svg');
                            &:hover,&:focus{
                                background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin_reverse_over.svg') no-repeat 0 0;
                                background-size: 100%;
                                @include mobile{
                                    background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin-m_reverse_over.svg') no-repeat 0 0;
                                    background-size: 100%;
                                }
                            }
                        } //사상
                        &.idx02{ left: 66.2%; top: 58.5%; } //울산
                        &.idx03{ left: 48.6%; top: 32.5%; } //구미
                        &.idx04{ left: 53.6%; top: 70.5%; } //상남
                        &.idx05{ left: 62%; top: 67.3%; } //양산
                        &.idx06{ left: 66.5%; top: 38.7%; } //포항
                        &.idx07{ left: 53%; top: 46.7%; } //달서
                        @include mobile{
                            &.idx01 {left: 51.3%; top: 78.3%; padding: 3vw 0 0 0;background-image: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin-m_reverse.svg'); background-size: 100%;}
                            &.idx02 {left: 56.5%; top: 58.5%; }
                            &.idx03{ left: 30%; top: 32.5%; }
                            &.idx04{ left: 36.6%; top: 69.5%; }
                            &.idx05{ left: 51.5%; top: 66.5%; }
                            &.idx06{ left: 59.7%; top: 39.2%; }
                            &.idx07{ left: 36.2%; top: 46.2%; }
                        }
                    }
                }
                &.kangwon{ 
                    background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_kangwon.svg') no-repeat 0 0;
                    background-size: 100%;
                    @include mobile{
                        background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_kangwon-m.svg') no-repeat 0 0;
                        background-size: 100%;
                    }
                    .pin{
                        &.idx01{ left: 73.6%; top: 40.7%; } //강릉
                        @include mobile{
                            &.idx01{ left: 67.5%; top: 40.5%; }
                        }
                    }
                }
                
                .pin{
                    color: #fff;
                    font-size: 14px;
                    text-align: center;
                    display: inline-block;
                    position: absolute;
                    width: 97px;
                    height: 38px;
                    padding: 7px 0 0 20px;
                    background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin.svg') no-repeat 0 0;
                    background-size: 100%;
                    
                    &.wide{
                        width: 106px;
                        height: 38px;
                        background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin_wide.svg') no-repeat 0 0;
                        background-size: 100%;
                        &:hover,&:focus{
                            background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin_wide_over.svg') no-repeat 0 0;
                            background-size: 100%;
                        }
                         @media all and (max-width: 1200px) and (min-width: 768px) { width: 8.83333vw; height: 3.16667vw; }
                         @include mobile{
                            background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin_wide-m.svg') no-repeat 0 0;
                            background-size: 100%;
                            width: 20.86050vw;
                            height: 8.21382vw;
                            &:hover,&:focus{
                                background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin_wide_over-m.svg') no-repeat 0 0;
                                background-size: 100%;
                            }
                    
                         }
                    }

                    @include mobile{
                        background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin-m.svg') no-repeat 0 0;
                        background-size: 100% 100%;
                        font-size: 3.38542vw;
                        width: 18.51369vw;
                        height: 8.21382vw;    
                        padding: 1.56454vw 0 0 0;

                    }
                    
                    &:hover,&:focus{
                        background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin_over.svg') no-repeat 0 0;
                        background-size: 100%;

                        @include mobile{
                            background: url('/lg5-common/images/MA/unmanned-store/unmanned-store_map_pin_over-m.svg') no-repeat 0 0;
                            background-size: 100% 100%;

                        }
                    }
                  
                    @media all and (max-width: 1200px) and (min-width: 768px) {
                        font-size: 1.16667vw;
                        width: 8.08333vw;
                        height: 3.16667vw;
                        padding: 0.58333vw 0 0 1.66667vw;
                    }
                }
            
            }
            .list{
                flex-basis: calc(100% - 70% - 6.5%);
                margin-left: 6.5%;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include unmanned-store-middle-target {
                    flex-basis: auto;
                    margin-left: 5vw;
                }
                @include mobile{
                    flex-basis: auto;
                    margin-top: 6.5px;
                    margin-left: 0;
                    padding: 0 30px;
                }


                > ol{
                    @include mobile{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: baseline;
                    }
                    > li{
                         @include mobile{
                             display: inline-block;
                             width: 50%;
                         }
                        a {
                            @include font(16px, 26px);
                            @media screen and (max-width: 1200px) and (min-width: 768px) {
                                        font-size: 1.33333vw; line-height: 2.16667vw
                            }
                            display: block;
                            font-weight: $font-weight-regular;
                            text-decoration: underline;
                            &::after {
                                content: '';
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                background: url('/lg5-common/images/icons/btn_arrow_right_16.svg') 4px 3px no-repeat;
                                @include unmanned-store-middle-target {
                                    width: 1.33333vw;
                                    height: 1.33333vw;
                                    background: url('/lg5-common/images/icons/btn_arrow_right_16.svg') 0.33333vw 0.25000vw;
                                    background-size: 100%;
                                }
                            }
                        }
                        &:not(:first-child){
                            margin-top: 24px;
                            @include unmanned-store-middle-target {
                                margin-top: 2.00000vw
                            }
                        }
                        @include mobile{
                            margin-top: 18.5px;
                        }
                    }
                }

                .bullet-list{
                    @include mobile{
                        margin-top: 40px;
                    }
                    > li{
                        margin-top: 0;
                        @include font-small;
                        @include unmanned-store-middle-target {
                            margin-top: 0.16667vw;
                            padding-left: 0.91667vw;
                            font-size: 1.16667vw;
                            line-height: 2.00000vw
                        }
                    }
                }
                .bullet-text{
                    @include mobile{
                        margin-top: 40px;
                    }
                    @include unmanned-store-middle-target {
                        margin-top: 0.16667vw;
                        padding-left: 0.91667vw;
                        font-size: 1.16667vw;
                        line-height: 2.00000vw
                    }
                }
                
            }

        }


        > ol {
            display: flex;
            flex-wrap: wrap;
            margin: -24px 0 0 -24px;
            > li {
                position: relative;
                width: 50%;
                padding: 24px 0 0 24px;
                &:nth-child(1) {
                    width: 100%;
                    .img-area {
                        max-width: 678px;
                    }
                    .text-area {
                        align-self: center;
                    }
                }
            }
            @include mobile {
                margin: -12px 0 0;
                > li {
                    width: 100%;
                    padding: 12px 0 0;
                }
            }
        }
    }

}

.unmanned-store-service {
    margin-bottom: 100px;
    padding: 80px 0;
    background-color: $color-bg;
    @include mobile {
        margin: 60px 0;
        padding: 60px 0;
        overflow: hidden;
    }
    .title {
        font-size: 40px;
        text-align: center;
        margin-bottom: 50px;
        @include mobile {
            font-size: 27px;
            margin-bottom: 24px;
        }
    }
    .slide-wrap {
        &.carousel-type {
            &.carousel-4 {
                max-width: 1380px;
                @include mobile {
                    max-width: none;
                }
                .slide-conts {
                    position: relative;
                    &::before {
                        position: absolute;
                        top: 90px;
                        right: -20px;
                        content: '';
                        width: 32px;
                        height: 32px;
                        background-image: url('/lg5-common/images/MA/unmanned-store/ic_calender_next.png');
                        background-position: 0 0;
                        background-repeat: no-repeat;
                        @include mobile {
                            display: none;
                        }
                    }
                    &:last-child {
                        &::before {
                            display: none;
                        }
                    }
                    .slide-box {
                        text-align: center;
                        .icon {
                            width: 200px;
                            height: 200px;
                            margin: 0 auto 30px auto;
                            background-color: #fff;
                            border-radius: 100%;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            @include mobile {
                                width: 180px;
                                height: 180px;
                                margin: 0 auto 25px auto;
                            }
                            img {
                                align-self: center;
                            }
                        }
                        .SlideNum {
                            font-size: 24px;
                            font-weight: 700;
                            margin-bottom: 10px;
                            display: block;
                            @include mobile {
                                font-size: 18px;
                                margin-bottom: 7px;
                            }
                        }
                        .SlideText {
                            font-size: 18px;
                            line-height: 28px;
                            @include mobile {
                                font-size: 14px;
                                line-height: 22px;
                            }
                            span {
                                color: $color-primary;
                            }
                        }
                    }
                }
                .btn-arrow {
                    &.prev {
                        @include mobile {
                            left: 30px;
                        }
                    }
                    &.next {
                        @include mobile {
                            right: 30px;
                        }
                    }
                }
            }
        }
    }
        
            
    
}
.btm-link {
    margin: 80px 0 120px;
    padding: 48px 0;
    text-align: center;
    @include mobile {
        margin: 60px 0;
        padding: 40px 16px;
    }
    &:before {
        display: block;
        width: 80px;
        height: 80px;
        margin: 0 auto 24px;
        background: url('/lg5-common/images/STC/lg-pay-logo.png') no-repeat 0 0;
        background-size: 100%;
        content: '';
    }
    .tit {
        font-weight: 700;
        @include font($font-large-2,34px);
        @include mobile {
            @include font($font-large-1,28px);
        }
    }
    .btn-area {
       margin-top: 24px;
    }
}
.title-wrap {
    max-width:1380px + 80px;
    margin:0 auto;
    padding:0 40px;
    text-align:center;
    .service-title {
        margin-bottom:64px;
        @include font(40px,56px);
        @include mobile {
            margin-bottom:32px;
            @include font(27px,38px);
        }
    }
}
.slide-wrap.carousel-type {
    &.carousel-1 {
        &.lg-pay {
            max-width: 1380px;
            margin: 0 auto;
            background: #f4f4fb;

            .indi-wrap {
                position: absolute;
                bottom: 32px;
                margin-top: 0;
                li {
                    padding: 0 4px;
                }
            }
            @include mobile {
                max-width: 100%;
                padding: 45px 0 64px;
                .indi-wrap {
                    bottom: 32px;
                }
            }
            .slide-controls {
                // display: none;
                right: 0;
                left: 0;
                transform: translateX(0px);
                .btn-arrow {
                    &.prev,
                    &.next {
                        transform: translateX(0);
                    }
                    &.prev {
                        left: -18px;
                    }
                    &.next {
                        right: -18px;
                    }
                }
                @media screen and (max-width: 1380px) {
                    .btn-arrow {
                        &.prev {
                            left: 20px;
                        }
                        &.next {
                            right: 20px;
                        }
                    }
                }
            }
            .slide-content {
                .slide-conts {
                    height: 455px;
                    .inner {
                        display: table;
                        width: 100%;
                        max-width: (1380px + 80px);
                        height: 100%;
                        margin: 0 auto;
                        padding: 0 40px;
                        font-size: 0;
                        @media screen and (min-width:(1380px + 80px + 1)) {
                            padding: 0 157px;
                        }
                    }
                    .slide-info-area {
                        display: table-cell;
                        width: 396px;
                        //margin-right: 93px;
                        vertical-align: middle;
                        .boxs {
                            .step {
                                font-weight: 700;
                                @include font-small;
                                color: $color-gray-1;
                            }
                            .title {
                                margin: 2px 0 16px;
                                font-weight: 700;
                                @include font($font-large-2,34px);
                            }
                            .desc {
                                @include font-medium;
                            }
                            .bullet-list {
                                margin-top: 8px;
                            }
                            .btn-area {
                                margin-top: 24px;
                            }
                        }
                    }
                    .visual-area {
                        display: table-cell;
                        width: 560px;
                        text-align: right;
                        vertical-align: middle;
                        img {
                            width: 100%;
                            max-width: 560px;
                        }
                    }

                    @include mobile {
                        height: 100%;
                        margin: 0;
                        padding-bottom: 14.2%;
                        .inner {
                            //display: flex;
                            //flex-direction: column;
                            //justify-content: space-between;
                            max-width: 100%;
                            padding: 0 16px;
                        }
                        .slide-info-area {
                            display: block;
                            width: 100%;
                            min-height: 202px;
                            padding-top: 0;
                            text-align: center;
                            .boxs {
                                padding: 0 20px;
                                .title {
                                    margin-bottom: 12px;
                                    @include font($font-large-1,28px);
                                }
                            }
                        }
                        .visual-area {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            //align-content: flex-end;
                            display: block;
                            width: 100%;
                            height: auto;
                            img {
                                max-width: 100%;
                                //height: auto;
                            }
                        }
                    }

                }
            }
        }
    }
}
