@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";


.pc-only {
    display: inline-block;
    @include mobile {
        display:none;
    }
}

.mo-only {
    display: none;
    @include mobile {
        display: inline-block;
    }
}
br.m{
    display:none;
    @include mobile{
        display: block;
    }
}

.mo-flex {
    //display: none;
    @include mobile {
        display: flex;
        justify-content: center;
    }
}

.mo-h2{
    @include mobile {
        font-size:20px;
    }
}
.form-wrap .forms .tit label.c-gray {
    color:#666; // BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어(점검 추가 수정)
}
// BTOCSITE-78875 [LGE.com] 주거용 시스템에어컨/환기 온라인 견적 문의 페이지 內 구분자 추가 요청의 건
.form-wrap .forms .tit label.c-darkgray {
    color:#8f8f8f !important;
    font-weight: 700;
}
.c-darkgray{
    color:#666;
    font-weight:300;
}
.select-wrap .ui-selectbox-wrap .ui-select-button .ui-select-text {
    color:#666;
}
.cont-wrap {
    overflow: hidden;
    width:100%;
    @include mobile {
        padding:0 16px;
    }
    .err-msg {
        margin-top: 8px;
        text-align: left; 
    }
    .form-wrap {
        max-width: 1380px;
        margin:0 auto;
        padding:10px 0 0;
        .form-head {
            text-align: center;
            margin-bottom: 49px;
            padding-top:14px;
            @include mobile{
                padding-top:0;
                margin-bottom:52px;
            }
            h2{
            font-size:24px;
                @include mobile{
                    line-height: 33px;
                }
            }
            /* BTOCSITE-6128 시스템에어컨 B2C온라인 견적 요청시 선택사항→필수사항 변경 요청 */
            .desc{
                width: 100%;
                margin: 8px auto 12px;
                text-align: center;
                font-size:16px;
                line-height: 26px;
                word-break: keep-all;
                @include mobile{
                    font-size:14px;
                    line-height: 22px;
                    margin-top:10px;    // BTOCSITE-79924 전기차충전기 견적요청 신청시 개인고객 정보 입수 화면 신규 생성 요청
                }
            }
        }
        .bdb{
            border-bottom:1px solid #000;
            padding-bottom:16px;
            @include mobile {
                padding-bottom:8px;
            }
            .tit {
                font-size:28px;
                @include mobile {
                    font-size:20px;
                    line-height:1.4;
                }
            }
        }
        // S :  BTOCSITE-45366 시스템에어컨 온라인 견적문의 시 로그인 여부 확인 및 추가 인증 여부 개선요청
        .inner {
            display: flex;
            justify-content: flex-start;
            @include mobile{
                display: block;
            }
            .desc{
                padding-top:6px;
                margin-left:16px;
                @include mobile{
                    font-size:14px;
                    line-height: 22px;
                    margin-left:0;
                    padding-top:4px;
                }
                
                .btn-text {
                    font-weight: 700;
                    color: #ea1917;
                    text-decoration: underline;
                    line-height: 1;
                    @include mobile {
                        line-height: 22px;
                    }
                }
            }
        }
        // E :  BTOCSITE-45366 시스템에어컨 온라인 견적문의 시 로그인 여부 확인 및 추가 인증 여부 개선요청
        .reqchk{
            font-size:14px;
            padding-top: 16px;
            @include mobile{
                font-size:13px;
                padding-top:9px;
                line-height:1.62;
            }
        }
        .reqchk::before{
            content:"*";            
            display: inline-block;
            margin-left: 1px;
            margin-right:2px;
            color: #ea1917;
            font-size: inherit;
            line-height: inherit;
            vertical-align: top;
        }
        .forms {
            margin-top:24px;
            @include mobile {
                margin-top:20px;
            }
            &.isLogin {
                margin-top:16px;
            }
            // BTOCSITE-79924 전기차충전기 견적요청 신청시 개인고객 정보 입수 화면 신규 생성 요청
            &.nIsLogin {
                margin-top: 16px;
                .err-msg {
                    margin-top: 1px;
                }
            }
            .conts {
                max-width:795px;
                @include mobile{
                    display: flex;
                }
                .input-wrap{
                    width:100%;
                }
                .select-wrap {
                    width:259px;
                    margin:0;
                    @include mobile{
                        // width: 100%; //210629
                    }
                }
                /* BTOCSITE-16282	LGE.COM 內 가정용 시스템에어컨 견적요청 페이지에 휴대폰 본인인증 절차 추가 */
                &.certification{
                    // BTOCSITE-79924 전기차충전기 견적요청 신청시 개인고객 정보 입수 화면 신규 생성 요청
                    &:has(.err-msg) {
                        @include pc {
                            display: inline-flex;
                        }
                        align-items: center;
                        flex-wrap: wrap;
                        .txt-certification {
                            + .btn {
                                position: relative;
                                top: 7px;
                                @include mobile {
                                    position: static;
                                    top: 0;
                                }
                            }
                        }
                        .err-msg {
                            @include mobile {
                                margin-top: 8px;
                                width: 100%;
                            }
                        }
                    }
                    @include mobile{
                        flex-direction:column;
                    }
                    .txt-certification{
                        display:inline-block;
                        margin-right:16px;
                        padding:7px 0;
                        font-size:16px;
                        line-height:26px;
                        @include mobile{
                            font-size:14px;
                            line-height:22px;
                            word-break:keep-all;
                            padding:0;
                        }
                        +.btn{
                            display:inline-block;
                            max-width:141px;
                            padding:10px 24px;
                            height:40px;
                            background:#fff;
                            border-radius:20px;
                            color:#222;
                            line-height:1;
                            font-weight:700;
                            &:hover {
                                background:$color-primary;
                                color:#fff;
                                border-color:$color-primary;
                            }
                            @include mobile{
                                margin-top:20px;
                                width:141px;
                                height:40px;
                                padding:0;
                                font-size:14px;
                                border-radius:22px;
                                align-self:center;
                            }
                        }
                    }
                }
                .name-input-wrap {
                    height:48px;
                    display: inline-block;
                    @include mobile{
                        display: flex;
                        width: 100%;
                    }
                }
                /* //BTOCSITE-16282	LGE.COM 內 가정용 시스템에어컨 견적요청 페이지에 휴대폰 본인인증 절차 추가 */
                .phone-input-wrap {
                    height:48px;
                    display: inline-block;
                    @include mobile{
                        display: flex;
                        width: 100%; //210629
                    }
                }
                .email-conts-wrap {
                    @include mobile{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                    }
                    .email-input-wrap {
                        display: inline-block;
                        @include mobile{
                            width: 100%;
                            display: block;
                            text-align: center;
                        }
                    }
                    .select-wrap{
                        @include mobile{
                            width: 100%;
                            display: block;
                            margin-top: 10px;
                        }
                    }
                }
                .addr-box-wrap{
                    @include mobile{
                        width: 100%;
                    }
                }
                
                // BTOCSITE-79924 전기차충전기 견적요청 신청시 개인고객 정보 입수 화면 신규 생성 요청 
                &:has(.err-msg) {
                    @include mobile {
                        flex-wrap: wrap;
                        .select-wrap {
                            flex: 1;
                            &:last-of-type {
                                margin-right: 0;
                            }
                        }
                        .err-msg {
                            width: 100%;
                        }
                    }
                }
            }
            /* BTOCSITE-16282	LGE.COM 內 가정용 시스템에어컨 견적요청 페이지에 휴대폰 본인인증 절차 추가 */
            &.nIsLogin, &.isLogin{
                display:none;
            }
            /* //BTOCSITE-16282	LGE.COM 內 가정용 시스템에어컨 견적요청 페이지에 휴대폰 본인인증 절차 추가 */
            .btn {
                min-width:118px;
                @include mobile {
                    min-width:103px;
                }
            }
            .tit {
                label, .label {
                    line-height: 24px;
                    span {
                        margin-top: 1px;
                        display: block;
                        line-height: 20px;
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
        .sub-forms-wrap{
            .sub-forms.bdn{
                border-bottom: 0;
                & + .sub-forms.bdn {
                    .terms-wrap {
                        margin-top:48px;
                        @include mobile {
                            margin-top:32px;
                        }
                    }
                }
                .terms-wrap {
                    font-size:0;
                    margin-top:32px;    // 
                    @include mobile {
                        margin-top:20px;
                    }
                    .tit-head {
                        display:inline-block;
                        width:calc(15.2% + 24px);
                        margin-top:0;
                        padding:12px 24px 0 0;
                        vertical-align:top; 
                        font-size:16px;
                        font-weight:700;  
                        @include mobile {
                            display:block;
                            width:100%;
                            font-size:18px;
                            padding:0;
                            line-height:32px;
                        }
                        &.req {
                            &:after {
                                content: '*';
                                display: inline-block;
                                margin-left: 1px;
                                color: #ea1917;
                                font-size: inherit;
                                line-height: inherit;
                                vertical-align: top;
                            }
                        }
                    }
                    .tit-center {
                        display:inline-block;
                        width:calc(100% - (25% + 24px));
                        max-width:795px;
                        margin-top:0;
                        @include mobile {
                            display:block;
                            width:100%;
                            margin-top:8px;
                        }
                    }
                    // BTOCSITE-79924 전기차충전기 견적요청 신청시 개인고객 정보 입수 화면 신규 생성 요청
                    .err-msg {
                        max-width: 795px;
                        margin-left: calc(15.2% + 24px);
                        @include mobile {
                            margin-left: 0;
                        }
                    }
                    .chk-wrap {
                        input {
                            & + label {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
            .sub-forms.subbox{
                border-bottom:1px solid #ddd;
                padding-bottom:40px;
                &:last-child {
                    border-bottom:none;
                    padding-bottom:0;
                }
                @include mobile {
                    padding-bottom:32px;
                }
            }
            .sub-forms{                
                .inner{
                    padding-top: 80px;
                    display: flex;
                    justify-content: flex-start;
                    @include mobile{
                        padding:52px 0 8px;
                        flex-direction: column;
                    }
                    .tit{
                       @include mobile{
                        //모바일
                       }
                    }
                    .desc{
                        padding-top:6px;
                        margin-left:16px;
                        @include mobile{
                            font-size:14px;
                            line-height: 22px;
                            margin-left:0;
                            padding-top:4px;
                        }
                    }
                    
                }
                .forms {
                    margin-top:24px;
                    @include mobile {
                        margin-top:20px;
                    }
                }
                .tit-head{
                    margin-top:36px;
                    p.desc{
                        font-size:16px;
                        font-weight: normal;
                        line-height: 26px;
                        margin:16px 0 12px;
                        @include mobile{
                            font-size:14px;
                            line-height: 22px;
                        }
                    }
                    h2{
                        @include mobile{
                            font-size:18px;
                        }
                    }
                }
                .tit-center{
                    margin: 16px 0 0;
                    border-radius: 8px;
                    // BTOCSITE-79924 전기차충전기 견적요청 신청시 개인고객 정보 입수 화면 신규 생성 요청
                    border: 1px solid #8f8f8f;
                    overflow: hidden;
                    .txt-wrap{
                        font-size:14px;
                        padding:25px 22px 20px 22px;
                        line-height:24px;
                        border-bottom: 1px solid #8f8f8f;
                        height: 135px;
                        overflow-y: scroll;
                        @include mobile{
                            font-size:12px;
                            line-height: 20px;
                            font-weight: 100;
                            height: 125px;
                            padding:21px 24px 10px 16px;
                            
                        }

                        /* B2C 4.0 약관동의 */
                        .policy_box {
                            color:#666;
                            h4 {
                                font-size: 16px;
                                font-weight: normal;
                                color: #666;
                            }
        
                            h5 {
                                margin-top: 35px;
                                padding-top: 37px;
                                font-size: 16px;
                                font-weight: normal;
                                color: #666;
                                border-top: 1px solid #ccc;
                            }
        
                            p {
                                color: #666;
                                line-height: 1.714;
                                margin-top:12px; 
                            }

                            ul,ol {
                                list-style-image: none;
                                list-style: none;
                                padding: 0;
                                margin: 0;
                                font-weight:normal;
                            }

                            ol {
                                margin-top: 19px;
                                li {
                                    margin-top:12px;
                                }
                            }

                            table {
                                margin: 20px 0 25px;
                                tr {
                                    td {
                                        text-align:left;
                                        color:#666; 
                                    }
                                }
                            }
                        }
                    }
                    .btn-agree{
                        padding:17px 24px;
                        @include mobile{
                            padding:12px 16px;
                        }
                    }
                }
                .table-wrap{
                    .tb_row, .tb-col {
                        table{
                            thead{
                                th{
                                    @include mobile{
                                        font-size:13px;
                                        font-weight: normal;
                                    }
                                }
                            }
                            tbody{
                                td{
                                    @include mobile{
                                        font-size:13px;
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                    }
                }
                
                .btn-wrap.pc-only{
                    width: 100%;
                    margin-top:80px;
                    text-align: center;
                    font-size:0;
                    button {
                        width:218px;
                        font-size:14px;
                        border-radius:6px;
                        font-weight:700;
                        &.inqueryBtn {
                            background:#fff;
                            color:#222;
                            border-color:#222;
                            &:hover {
                                background:$color-primary;
                                color:#fff; 
                                border-color:$color-primary;
                            }
                        }
                        &:focus {
                            outline:1px solid #000;
                        }
                        &.estimateBtn {
                            &:focus {
                                outline:2px solid #000;
                            }
                        }
                    }
                    button:first-child{
                        margin-right:8px;
                    }                    
                }
                
                .forms.dl1{
                    margin-top:24px;
                    @include mobile {
                        margin-top:20px;
                    }
                    .conts{
                        .box{
                            .prd-name{
                                padding:11px 0;
                                @include mobile {
                                    padding:0;
                                }
                                input{
                                    @include mobile{
                                        font-size:14px;
                                    }
                                }

                                span {
                                    display: inline-block;
                                    font-size:16px; 
                                    vertical-align:middle;
                                    line-height: 1.65em;

                                    @include mobile{
                                        font-size:14px;
                                        line-height: 1.45em;
                                    }
                                }
                            }
                        }
                    }
                }
                .forms.dl2{
                    .conts{
                        @include mobile{
                            display: flex;
                            flex-direction: column;
                            margin-top:8px;
                        }

                        @include mobile {
                            &:has( > .rdo-wrap:last-child:nth-child(2)) {
                                flex-direction: inherit;
                                .rdo-wrap {
                                    width: 50%;
                                }
                            }
                        }
                        .rdo-wrap{
                            margin:11px 0;
                            @include mobile {
                                margin:8px 0;
                            }
                        }
                    }
                }
                .forms{
                    .box{
                        .prd-name{
                            input#inp05{
                                width:100%;
                            }
                        }
                    }
                }
                .sub-forms-box{
                    margin-top:40px;
                    @include mobile {
                        margin-top:32px;
                    }
                    .tit{
                        font-size:24px;
                        @include mobile{
                            font-size: 18px;
                        }
                    }
                    .forms.dl1{
                        .chk-wrap{
                            margin-top: 8px;
                        }
                    }
                    .forms.dl2{
                        & + .forms.dl2 {
                            margin-top:24px;
                        }
                        .tit{
                            // padding-top:20px;
                            @include mobile{
                                padding-top: 0;
                            }
                        }
                        .conts{
                            @include mobile{
                                display: block;
                                width: 100%;
                                margin-top:8px;
                            }
                            .chk-wrap{
                                @include mobile{
                                    width: calc(50% - 5px);
                                    float: left;
                                }
                                label{
                                    padding-top: 10px;
                                    @include mobile {
                                        padding:8px 0 8px 30px;
                                    }
                                }
                                label::after{
                                    top:12px;
                                }
                            }
                            .set2{
                                width:383px;
                                display: inline-block;
                                margin-top:0;
                                vertical-align:11px;
                                @include mobile {
                                    margin-top:8px;
                                }
                                .tit{
                                    padding:11px 16px 11px 18px;
                                    width:auto;
                                    label{
                                        font-weight: 400;
                                    }
                                    label.m2{
                                        position: absolute;
                                        right:0;
                                        top:0;

                                    }
                                    @include mobile{
                                        text-align: left;
                                        padding:0;
                                    }
                                }
                                .conts{
                                    width:auto;
                                    .input-wrap {
                                        width: 100%;
                                        max-width: 160px;
                                        @include mobile{
                                            // display: flex;
                                            max-width: 100%;
                                        }

                                        // input { 
                                        //     width:80%; margin-right:10px;
                                        //     text-align: right;
                                        // }
                                        // .unit {
                                        //     height:100%;
                                        //     font-size: 16px;
                                        //     display:inline-block;
                                        //     line-height:48px;

                                        // }
                                    }

                                }
                                .input-wrap {
                                    .err-msg {
                                        @include pc {
                                            white-space:nowrap;
                                        }
                                    }
                                }
                                @include mobile{
                                    width: 100%; //210629
                                }
                            }
                            .rdo-wrap {
                                @include mobile {
                                    width:50%;
                                }    
                            }
                            // BTOCSITE-79924 전기차충전기 견적요청 신청시 개인고객 정보 입수 화면 신규 생성 요청
                            &:has(> :nth-child(5)) {
                                .rdo-wrap {
                                    @include mobile {
                                        width: 33.333%;
                                    }
                                }
                            }
                        }
                    }
                    .forms.dl3{
                        // BTOCSITE-79924 전기차충전기 견적요청 신청시 개인고객 정보 입수 화면 신규 생성 요청
                        &:has(.company-box-wrap) {
                            .tit {
                                label{
                                    span{
                                        @include mobile {
                                            margin-top: 1px;
                                        }
                                    }
                                }
                            }

                        }
                        .tit{
                            padding: 0;
                            @include mobile{
                                
                            }
                            label{
                                @include mobile{
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    color: #666;
                                    font-weight: 500;
                                }
                                span{
                                    font-weight: normal;
                                    font-size: 14px;
                                    @include mobile{
                                        margin:-22px 0 0 10px;
                                        display: flex;
                                        align-items: center;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                        .conts{
                            .company-box-wrap{
                                @include mobile{
                                    display: flex;
                                    width: 100%;
                                    flex-direction: column;
                                }
                                .select-wrap{
                                    width:168px;
                                    margin-right:8px;
                                    @include mobile{
                                    width: 100%;   
                                    }
                                }
                                .conts-wrap{
                                    height: 40px;
                                    display: flex;
                                    @include mobile{
                                        height:100%;
                                        display: block;
                                    }
                                    .input-wrap{
                                        padding:10px 0;
                                        display: flex;
                                        align-items: center;
                                        @include mobile{
                                            flex-wrap: wrap;
                                            width: 100%;
                                            margin-bottom: 8px;
                                            padding:0;
                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                        }
                                        input{
                                            margin-right:8px;
                                            color:#666;
                                            @include mobile{
                                                margin-bottom:10px;
                                            }
                                        }
                                        input:first-child {
                                            @include mobile {
                                                margin-right: 0;
                                                width: 100%;
                                            }
                                            &:last-child {
                                                @include mobile {
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }
                                        input:nth-child(n+2){                                            
                                            @include mobile{
                                                display: block;
                                                width: calc(50% - 4px);
                                                float: left;
                                                margin-bottom: 0;
                                            }
                                        }
                                        input:last-child{
                                            margin-right: 0;
                                        }
                                    }
                                }
                                &.company-type {                                    
                                    // BTOCSITE-78875 [LGE.com] 주거용 시스템에어컨/환기 온라인 견적 문의 페이지 內 구분자 추가 요청의 건                                    
                                    .select-wrap {
                                        flex: 1;
                                        @include mobile {
                                            margin-right: 0;
                                            margin-bottom: 8px;
                                            display: inline-block;
                                            width: calc(50% - 4px);
                                        }
                                        &:first-child {
                                            display: block;
                                            width: auto;
                                            flex: none;
                                            @include mobile {
                                                width: 100%;
                                            }
                                        }
                                        &:nth-child(2) {
                                            margin-right: 8px;
                                        }
                                        &:nth-of-type(4) {
                                            display: block;
                                            margin-right: 0;
                                            @include mobile {
                                                width: 100%;
                                            }
                                        }
                                    }
                                    .input-wrap {
                                        padding: 8px 0 10px;
                                        display: flex;
                                        align-items: center;
                                        @include mobile {
                                            margin-top: 0;
                                            padding: 0;
                                        }
                                        input[type='text'] {
                                            margin-right: 8px;
                                            max-width: 211px;
                                            @include mobile {
                                                max-width: inherit;
                                                &:last-child {
                                                    margin-right: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .rdo-wrap{
                                margin:11px 0;
                                @include mobile {
                                    margin:8px 0;
                                    width:50%;
                                }
                            }
                            .prod-box-wrap{
                                @include mobile{
                                    display: block;
                                }
                                .chk-wrap{
                                    @include mobile{
                                        margin-top:16px;
                                        width: 50%;
                                        
                                        input:nth-child(n+2){                                            
                                            @include mobile{
                                                display: block;
                                                width: 50%;
                                                float: left;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        & + .forms.dl3 {
                            .tit {
                                padding:11px 24px 11px 0;
                                @include mobile {
                                    padding:0;
                                }
                            }
                        }
                    }
                    .forms.dl4{
                        .conts{
                            .select-wrap{
                                margin-right:8px;
                                &:last-child {
                                    margin-right:0;
                                }
                                &:last-of-type {
                                    margin-right: 0;
                                }
                            }
                            //210629
                            .input-wrap{
                                .text-box{
                                    padding-top:20px;
                                    padding-bottom:100px;
                                    @include mobile{
                                        padding-top:20px;
                                        padding-bottom:120px;
                                    }
                                }
                            }
                            //210629
                        }
                    }
                }
            }
        }        
        // BTOCSITE-79924 전기차충전기 견적요청 신청시 개인고객 정보 입수 화면 신규 생성 요청
        &:has(.reqchk) {
            .forms:nth-of-type(1) {
                @include mobile {
                    margin-top: 16px;
                }
            }
        }
    }
    .rdo-wrap input + label {
        margin-right:30px;
        @include mobile {
            margin-right:0;
        }
    }
    .chk-wrap input + label {
        margin-right:30px;
        @include mobile{
            
        }
    }
    .chk-wrap input + label:nth-child(2n) {
        @include mobile{
            margin-right:10px;
        }
    }
    .chk-wrap input + label:nth-child(2n-1) {
        @include mobile{
            margin-right:15px;
        }
    }
}

.btn-wrap.mo-only{
    display: none;
    @include mobile{
        position:fixed;
        z-index:108;
        bottom:0;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top:60px;
        padding:14px 16px;
        background:#fff;
        border-top:1px solid #d4d4d4;
        .btn.mo{
            @include mobile {
                width: 50%;
                height:50px;
                padding:0;
                border-radius:6px;
                font-weight:700;
                font-size:14px;
                &.inqueryBtn {
                    background:#fff;
                    color:#222;
                    border-color:#222;
                }
                &:first-child {
                    margin-right:8px;
                }
                &:focus-visible {
                    outline:1px solid #000;
                }
                &.estimateBtn {
                    &:focus-visible {
                        outline:2px solid #000;
                    }
                }
            }
        }
    }
}

/*온라인 견적 문의내역 조회*/
.inquiry-search{
    @include mobile{
        padding-bottom:100vh;
    }
    .form-wrap{
        .form-head{
            margin-bottom: 0;
        }
    }
}

/*온라인 견적 문의 내역 조회 결과*/
.inquiry-area{   
    @include mobile{
        padding-bottom:52px;
    } 
    .form-wrap{
        @include mobile{
            padding:0;
        }
        .form-head{
            margin-bottom: 65px;
            @include mobile{
                margin-bottom: 32px;
            }
            .desc{
                max-width: 430px;
            }
        }
        .task-num-wrap {
            text-align:center;
            margin-top:-33px;
            @include mobile {
                margin-top:0;
            }
            .task-num{
                display:inline-block;
                border-radius:8px;
                background: #f8f8f8;
                font-size:0;
                padding:16px 24px;
                span {
                    display:inline-block;
                    position:relative;
                    font-size:18px;
                    line-height:27px;
                    &.number {
                        padding-left:22px;
                        margin-left:20px;
                        color:#ea1917;
                        font-weight:700;
                        &:before {
                            content:'';
                            position:absolute;
                            top:0;
                            left:0;
                            width:2px;
                            height:100%;
                            background:#ddd;
                        }
                    }
                }
                & + .sub-forms-wrap {
                    .inner.bdb {
                        padding-top:60px;
                        @include mobile {
                            padding-top:32px !important;
                        }
                    }
                }
                @include mobile{
                    max-width:100%;
                    display:block;
                    padding:10px 0;
                    text-align:center;
                    border-radius:4px;
                    span {
                        font-size:14px;  
                        line-height:20px;  
                        &.number {
                            padding-left:13px;
                            margin-left:12px;
                            &:before {
                                top:50%;
                                transform:translateY(-50%);
                                width:1px;
                                height:13px;
                            }
                        }
                    }
                }
            }    
        }
        h2.tit{
            margin-top:50px;
            @include mobile{
                margin-top:40px;
                font-size:18px;
                margin-bottom:10px;
            }
        }
        .sub {
            margin-top:50px;
            @include mobile{
                margin-top:20px;
            }
        }
        .sub-forms-wrap {
            .sub-forms {
                .inner.bdb {
                    padding-top:60px;
                    .tit {
                        line-height:27px;
                    }
                    & + .forms {
                        margin-top:14px;
                        @include mobile {
                            margin-top:8px;
                        }
                    }
                    @include mobile {
                        padding-top:32px ;
                    }
                }
                .forms {
                    margin-top:4px;
                    @include mobile {
                        display:flex;
                        margin-top:0;
                    }
                }    
            }
            & + .sub-forms-wrap {
                .inner.bdb {
                    padding-top:70px;
                    @include mobile {
                        padding-top:24px;
                    }
                }
            }
        }
        .forms{
            .tit{
                padding:10px 24px 10px 0;
                @include mobile{
                    padding:8px 24px 8px 0;
                    flex-basis: 50%;
                }
            }
            .conts{
                .input-text{
                    height: 48px;
                    padding: 10px 15px;
                    @include mobile{
                        flex-basis: 100%;
                        padding:8px 0;
                        height:auto;
                    }
                    input{
                        width:80%;
                        @include mobile{
                            &.pc-only {
                                display:none;
                            }
                            font-size:14px;
                            width: 100%;
                            display: inline-block;
                            max-width: 200px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;

                        }
                    }
                    textarea {
                        min-height:39px;
                        font-size:14px;
                        line-height:20px;
                        &.mo-only {
                            @include mobile {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
                @include mobile {
                    margin-top:0;
                }
            }
        }
    }

}


//BTOCSITE-5498
.link-agree {
    width:calc(100% - (25% + 24px));
    max-width:795px;
    margin-top: 16px;
    margin-left:calc(15.2% + 24px);
    text-align: right;
    .btn-link {
        font-weight: 500;
        text-decoration:none;
    }
    @include mobile {
        width:auto;
        margin:8px 0 30px;
    }
}


/* 검색바 */
.KRP0031 {
	.btn-delete {
		position:absolute;
		top:50%;
		right:54px;
		width:20px;
		height:20px;
		margin-top:-10px;
		background:url('/lg5-common/images/icons/icon-delete-20.svg') no-repeat 0 0;
	
		@include mobile {
			right: 50px;
		}
	}
	.btn-search {
		position:absolute;
		top:0;
		right:0;
		width:52px;
		height:48px;
		background:16px center/24px 24px url(/lg5-common/images/icons/icon-search-48-new.svg) no-repeat;
	
		@include mobile {
			width:47px;
			height:46px;
            background-position:7px center;
		}
	}

	.search-wrap {
		text-align:center;
		padding:52px 0 220px;
		@include mobile {
            padding:20px 0 16px;
		}
		.search-box {
			display:inline-block;
			position:relative;
			width:678px;
			text-align:left;
			@include mobile {
				display:block;
				width:100%;
			}
		}
		.search-input {
			position:relative;
			width:678px;
			margin:0 auto;
			text-align:left;
			input[type=text] {
				width:100%;
				padding:10px 52px 10px 24px;
				@include font-medium;
				border-radius:24px;
				border:1px solid #ddd;
				background:#fff;
				// text-transform:uppercase;
                @include mobile {
                    padding:11px 47px 11px 24px;
                }
                &::-webkit-input-placeholder,
                &:-moz-placeholder,
                &:-ms-input-placeholder {
                    color:#666;
                }
                &::placeholder {
                    color:#666;
                }
                &:focus-visible {
                    outline-color:#000;
                }
			}
			
			@include mobile {
				width:100%;
			}
		}
		.search-more {
			position:absolute;
			left:calc(100% + 16px);
			top:23px;
			white-space:nowrap;
			transform:translateY(-50%);
			@include mobile {
				position:static;
				margin-top:16px;
				text-align:center;
				transform:translateY(0);
			}
			.btn {
				min-width:64px;
				padding:3px 11px 3px 32px;
				border-radius:16px;
				@include font-small;
				border-color:#000;
				background:transparent;
				color:#000;
			}
		}
		.search-error {
			display:none;
			margin-top:6px;
			color:#f00;
			@include font-small;
			vertical-align:top;
			&::before {
				content:' ';
				display:inline-block;
				width:20px;
				height:20px;
				margin:2px 4px 0 0;
				background: url("/lg5-common/images/icons/icon-error.svg") center no-repeat;
				vertical-align:top;
				@include mobile {
					margin-top:1px;
				}
			}
		}
	}
}

// S : BTOCSITE-61337 [시스템지원팀] LGE.COM 시스템에어컨 온라인 견적페이지 수정 요청
@include pc {
    &.new-form-style {
        padding-top: 28px;
    }
}
// E : BTOCSITE-61337 [시스템지원팀] LGE.COM 시스템에어컨 온라인 견적페이지 수정 요청
